import * as React from 'react';
import {ClockCircleOutlined, LinkOutlined} from '@ant-design/icons';
import {Button, Space} from 'antd';
import Card from '../../../components/Card.js';
import {calendarEntry} from '../../../utils/dateTime.ts';
import {cyrb53} from '../../../utils/utils.ts';
import {kebabCase} from 'lodash';

export const downloadCal = (event) => () => {
  const data = calendarEntry({
    model: {
      startDateTime: event.start_datetime,
      endDateTime: event.end_datetime
    },
    summary: event.title,
    uid: `${cyrb53('mikeric')}${cyrb53('eventid' + event.id)}${cyrb53('eventtitle' + event.title)}`,
    location: `${event.venue}, ${event.venue_address}`,
  });

  const blobConfig = new Blob(
    [data],
    {type: 'data:attachment/text'}
  );

  const blobUrl = URL.createObjectURL(blobConfig);
  const anchor = document.createElement('a');
  anchor.href = blobUrl;
  anchor.target = '_blank';
  anchor.download = `${kebabCase(event.title)}.ics`;

  anchor.click();
  URL.revokeObjectURL(blobUrl);
};

export const handleTicketRedirect = (event) => (e) => {
  e.preventDefault();
  const anchor = document.createElement('a');
  anchor.href = event.ticket_url;
  anchor.target = '_blank';
  anchor.rel = 'noreferrer noopener nofollow';

  anchor.click();
}

const EventCard = ({event}) => (
  <Card
    title={event.title}
    subtitle={
      <div>
        <div className={'flex flex-row'}>
          <div className={'w-8'}><ClockCircleOutlined/></div>
          <div>
            <span>{event.start_datetime.format('dddd, DD.MM.YYYY')}</span><br/>
            <span>{event.start_datetime.format('HH:mm')} Uhr - {event.end_datetime.format('HH:mm')} Uhr</span>
          </div>
        </div>
        <div className={'flex flex-row mt-4'}>
          <div className={'w-8'}><LinkOutlined/></div>
          <div>
            <a href={event.venue_url} target={'_blank'} rel={'noreferrer noopener nofollow'}>{event.venue}<br/>
              <address>
                {event.venue_address}
              </address>
            </a>
          </div>
        </div>
      </div>
    }
    footer={(
      <div className={'text-right'}>
        <Space>
          {!!event.ticket_url && (
            <Button
              className={'flex justify-center items-center p-0'}
              type={'primary'}
              shape={'circle'}
              size={'large'}
              onClick={handleTicketRedirect(event)}
              icon={<ion-icon name="ticket-outline"></ion-icon>}
            />
          )}
          <Button
            className={'flex justify-center items-center p-0'}
            type={'primary'}
            shape={'circle'}
            size={'large'}
            onClick={downloadCal(event)}
            icon={<ion-icon name={'calendar-outline'}/>}
          />
          {!!event.venue_address && !!event.venue && (
            <Button
              className={'flex justify-center items-center p-0'}
              type={'primary'}
              shape={'circle'}
              size={'large'}
              href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(`${event.venue}, ${event.venue_address}`)}`}
              target="_blank"
              icon={<ion-icon name={'car-outline'}/>}
            />
          )}
          {!!event.venue_address && !!event.venue && (
            <Button
              className={'flex justify-center items-center p-0'}
              type={'primary'}
              shape={'circle'}
              size={'large'}
              href={`https://www.sbb.ch/de/kaufen/pages/fahrplan/fahrplan.xhtml?nach=${encodeURI(event.venue_address)}&an=true&datum=${event.start_datetime.format('DD.MM.YYYY')}&zeit=${event.start_datetime.subtract(1, 'hour')
                .format('HH:mm')}`}
              target="_blank"
              icon={<ion-icon name={'train-outline'}/>}
            />
          )}
        </Space>
      </div>
    )}
  />
);

export default EventCard;
