import * as React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import EventList from './partials/EventList';
import ShortDescription from './partials/ShortDescription';
import AdditionalInfo from './partials/AdditionalInfo';
import Contact from './partials/Contact';
import Appear from '../../layouts/Appear.js';
import AudioVideoTeaser from './partials/AudioVideoTeaser.js';
import PlateChristeningHero from './partials/PlateChristeningHero';

const IndexPage = () => {

  return (
    <DefaultLayout>
      {/*<Hero/>*/}
      <PlateChristeningHero/>
      <div className={'py-1'}>
        <Appear>
          <ShortDescription/>
        </Appear>
        <Appear>
          <AdditionalInfo/>
        </Appear>
        <Appear>
          <AudioVideoTeaser/>
        </Appear>
        <Appear>
          <EventList/>
        </Appear>
        <Appear>
          <Contact/>
        </Appear>
      </div>
    </DefaultLayout>
  );
};

export default IndexPage;
