import React, {useEffect, useState} from 'react';
import {Button, Image, Space} from 'antd';
import logo from '../../../assets/mikeric_logo.png';
import cupcakes from '../../../assets/cupcakes_cover.jpg';
import useFirestore from '../../../hooks/useFirestore';
import Card from '../../../components/Card';
import Link from "../../../components/Link";
import {ClockCircleOutlined, LinkOutlined} from "@ant-design/icons";
import {downloadCal, handleTicketRedirect} from "./EventCard";

const Logo = () => (
  <Image
    alt={'Mike Eric Logo'}
    className={'filter drop-shadow'}
    style={{width: '300px', height: 'auto'}}
    src={logo}
    preview={false}
  />
);

const Cupcakes = () => (
  <Image
    alt={'Mike Eric Logo'}
    className={'filter drop-shadow'}
    src={cupcakes}
    preview={false}
  />
);

const PlateChristeningHero = () => {
  const eventStore = useFirestore('events');
  const [cupcakesEvent, setCupcakesEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading && eventStore) {
      (async () => {
        try {
          setIsLoading(() => true);
          const events = await eventStore.getAll();
          const event = events.find((e) => e.id === 'aCURX7YSUHsHqAztMyfK');
          setCupcakesEvent(event);
        } finally {
          setIsLoading(() => false);
        }
      })();
    }
  }, [eventStore]);

  return (
    <div className={'relative bg-gray-800'}>
      <div className="grid grid-cols-12 gap-8 py-8 px-2 sm:gap-4 sm:p-4">
        <div className="col-span-12 xl:col-span-4 2xl:col-span-4 flex items-center justify-center">
          <Logo/>
        </div>
        <div className="col-span-12 xl:col-span-8 2xl:col-span-8">
          <Card title={'Cupcakes Plattentaufe'} titleProps={{className: 'text-lg font-bold'}}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 sm:col-span-4">
                <div className="flex flex-col gap-4">
                  <Cupcakes/>
                  {!!cupcakesEvent && (
                    <div>
                      <div className={'flex flex-row'}>
                        <div className={'w-8'}><ClockCircleOutlined/></div>
                        <div>
                          <span>{cupcakesEvent.start_datetime.format('dddd, DD.MM.YYYY')}</span><br/>
                          <span>{cupcakesEvent.start_datetime.format('HH:mm')} Uhr - {cupcakesEvent.end_datetime.format('HH:mm')} Uhr</span>
                        </div>
                      </div>
                      <div className={'flex flex-row mt-2'}>
                        <div className={'w-8'}><LinkOutlined/></div>
                        <div>
                          <a
                                  href={cupcakesEvent.venue_url}
                                  target={'_blank'}
                                  rel={'noreferrer noopener nofollow'}
                          >
                            {cupcakesEvent.venue}<br/>
                            <address className={'p-0 m-0'}>
                              {cupcakesEvent.venue_address}
                            </address>
                          </a>
                        </div>
                      </div>
                      <div className={'flex flex-row mt-2'}>
                        <div className={'w-8'}>
                          <ion-icon name="ticket-outline"></ion-icon>
                        </div>
                        <div>
                          <Link
                                  target={'_blank'}
                                  href={cupcakesEvent.ticket_url}
                          >
                            Tickets (CHF 20.-)
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-span-12 sm:col-span-8 ">
                <p className={'m-0 p-0'}>
                  Der heisseste Blues-Export aus dem Limmattal kehrt nach Hause zurück! Mit im Gepäck haben sie
                  eine neue Platte, die es gebührend zu taufen gilt! Und wo wäre dies schöner als da, wo alles
                  begann?!<br/><br/>
                  Euch erwarten nicht nur neue Songs aus der Feder dieser sympathischen Kapelle, sondern
                  auch ein Ausflug zurück zu den Wurzeln von Mike Eric, sowie natürlich die verrückteste und
                  verstörendste
                  Blues-Show diesseits der Limmat. Denn seit über 20 Jahren haben sie sich dem Blues
                  verschrieben,
                  lassen es sich aber nicht nehmen, den klassischen Blues in Frage zu stellen und Einflüsse zu
                  verarbeiten, die man nicht erwartet, aber lieben lernt. Mit Schalk, Pauken und einer bissigen
                  Obszönität bringt
                  die Mike Eric Blues Band eine Energie auf die Bühne, die ihresgleichen sucht.<br/><br/>
                  In den Worten von Mike Eric: „Dietikon, are you ready? cause we the fuck are! Buckle up and
                  here we GO!“<br/><br/>
                </p>
              </div>
            </div>
            {!!cupcakesEvent && (
              <div className={'pt-4'}>
                <div className={'text-right'}>
                  <Space>
                    {!!cupcakesEvent.ticket_url && (
                      <Button
                        className={'flex justify-center items-center p-0'}
                        type={'primary'}
                        shape={'circle'}
                        size={'large'}
                        onClick={handleTicketRedirect(cupcakesEvent)}
                        icon={<ion-icon name="ticket-outline"></ion-icon>}
                      />
                    )}
                    <Button
                      className={'flex justify-center items-center p-0'}
                      type={'primary'}
                      shape={'circle'}
                      size={'large'}
                      onClick={downloadCal(cupcakesEvent)}
                      icon={<ion-icon name={'calendar-outline'}/>}
                    />
                    {!!cupcakesEvent.venue_address && !!cupcakesEvent.venue && (
                      <Button
                        className={'flex justify-center items-center p-0'}
                        type={'primary'}
                        shape={'circle'}
                        size={'large'}
                        href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURI(`${cupcakesEvent.venue}, ${cupcakesEvent.venue_address}`)}`}
                        target="_blank"
                        icon={<ion-icon name={'car-outline'}/>}
                      />
                    )}
                    {!!cupcakesEvent.venue_address && !!cupcakesEvent.venue && (
                      <Button
                        className={'flex justify-center items-center p-0'}
                        type={'primary'}
                        shape={'circle'}
                        size={'large'}
                        href={`https://www.sbb.ch/de/kaufen/pages/fahrplan/fahrplan.xhtml?nach=${encodeURI(cupcakesEvent.venue_address)}&an=true&datum=${cupcakesEvent.start_datetime.format('DD.MM.YYYY')}&zeit=${cupcakesEvent.start_datetime.subtract(1, 'hour')
                                .format('HH:mm')}`}
                        target="_blank"
                        icon={<ion-icon name={'train-outline'}/>}
                      />
                    )}
                  </Space>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PlateChristeningHero;
