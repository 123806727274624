const Card = ({children, title, subtitle, body, footer, square, titleProps = {}, ...props}) => {
  const defaultClasses = [];
  const defaultBodyClasses = ['flex-grow', 'py-4', 'px-8'];

  if (square) {
    defaultClasses.push('aspect-w-1 aspect-h-1');
  }

  if (!title || !subtitle) {
    const removeIdx = defaultBodyClasses.findIndex((c) => c === 'py-4');
    defaultBodyClasses.splice(removeIdx, 1);
    defaultBodyClasses.push('py-8');
  }

  return (
    <div className={['bg-white shadow-md rounded-md overflow-x-hidden overflow-y-auto', ...defaultClasses].join(' ')} {...props}>
      <div className={'flex flex-col'}>
        {
          (title || subtitle) && (
            <div className={'pt-8 pb-4 px-8'}>
              {title && <div className={'text-lg font-bold h-16'} {...titleProps}>{title}</div>}
              {subtitle && <div className={'text-base pt-2 text-gray-500'}>{subtitle}</div>}
            </div>
          )
        }
        <div className={defaultBodyClasses.join(' ')}>{children}</div>
        {footer && <div className={'pb-8 pt-4 px-8'}>{footer}</div>}
      </div>
    </div>
  );
};

export default Card;
